import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import api from "@/services/api.js";
import Vuex from "vuex";
import "./assets/css/main.css";

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.common["Authorization"] = "Token " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const app = createApp(App)
  .use(Vuex)
  .use(i18n)
  .use(router);

app.config.globalProperties.$http = api;

app.mount("#app");
