import { createRouter, createWebHashHistory } from "vue-router";
import store from "../store";

const routes = [
  {
    path: "/",
    name: "sensor-map",
    redirect: { name: "project-list" },
  },
  {
    path: "/projects/",
    component: () =>
      import(/* webpackChunkName: "projects" */ "../views/ProjectsList.vue"),
    name: "project-list",
    meta: {
      title: "asd",
    },
  },
  {
    path: "/projects/:id/",
    component: () =>
      import(
        /* webpackChunkName: "projectsDetail" */ "../views/ProjectsDetail.vue"
      ),
    name: "project-detail",
  },
  {
    path: "/login/",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/login/",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch("fetchAccessToken");

  document.title = "Sensor DKS";

  if (to.name === "login") {
    if (store.state.accessToken) {
      next({ name: "sensor-map" });
    }
  } else if (!store.state.accessToken) {
    next({ name: "login" });
  }
  next();
});

export default router;
