export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "navigation": {
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
    "sensors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sensors"])}
  },
  "sensor-map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sensor Map"])},
  "temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature"])},
  "sensor": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 sensor"]), _normalize([_interpolate(_named("n")), " sensors"])])},
  "display-zero-currents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display zero currents"])},
  "export-graph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export Graph"])},
  "loading-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading data"])},
  "zero-current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zero current"])},
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
  "measurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measurement"])}
}